var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mnemonic-phrase-table" },
    _vm._l(_vm.data, function (p, key) {
      return _c(
        "v-col",
        { key: key, attrs: { cols: "6", lg: "3", md: "3", sm: "4" } },
        [
          _c("div", { staticClass: "phrase" }, [
            _c("span", [_vm._v(_vm._s(key + 1) + ".")]),
            _vm._v(" " + _vm._s(p) + " "),
          ]),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }